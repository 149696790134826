<template>
  <!-- 回答问题 -->
  <div class="questionContainer">
    <div class="back">
      <span @click="$router.go(-1)"
        ><i class="el-icon-arrow-left"></i>返回</span
      >
    </div>
    <div class="mainQuestion">
      {{ questionDetail.interviewQuestions }}
      <div
        class="imageContainer"
        v-if="questionDetail.pictures && questionDetail.pictures.length > 0"
        style="margin-top: 12px"
      >
        <el-image
          v-for="(image, index) in questionDetail.pictures"
          style="width: 100px; height: 100px; margin-right: 12px"
          :src="image"
          :key="index"
          @click="
            showIndex = index;
            showViewer = true;
          "
        />
      </div>
    </div>
    <el-image-viewer
      v-if="showViewer"
      :initial-index="showIndex"
      :url-list="questionDetail.pictures"
      :on-close="
        () => {
          showViewer = false;
        }
      "
    />
    <div class="form">
      <el-form ref="form" :model="form" label-width="140px" :rules="rules">
        <el-form-item label="思路" prop="idea">
          <el-input type="textarea" v-model="form.idea"></el-input>
        </el-form-item>

        <el-form-item label="主体代码" prop="main">
          <Toolbar
            style="
              border: 1px solid #dcdfe6;
              border-bottom: none;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
            "
            :editor="editorId"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />

          <!-- 编辑器 -->
          <Editor
            style="
              height: 200px;
              border: 1px solid #dcdfe6;
              border-top: none;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            "
            :editor="editorId"
            :defaultConfig="editorConfig"
            :defaultContent="getDefaultContent"
            :mode="mode"
            @onChange="onChange"
            @onCreated="onCreated"
          />
        </el-form-item>
        <el-form-item label="Case1" prop="casus[0]">
          <el-input type="textarea" v-model="form.casus[0]"></el-input>
        </el-form-item>
        <el-form-item label="Case2">
          <el-input type="textarea" v-model="form.casus[1]"></el-input>
        </el-form-item>
        <el-form-item label="上传运行成功截图" prop="casePictures">
          <el-upload
            multiple
            :action="uploadUrl"
            ref="upload"
            list-type="picture-card"
            :on-success="handlePicSuccess"
            :before-upload="beforeUpload"
            :limit="5"
            accept=".jpg, .jpeg, .png, .gif"
            :data="{ needCut: true, isPublic: true }"
            :headers="{ 'X-Access-Token': token }"
            :on-remove="removeFile"
          >
            <i class="el-icon-plus" ref="uploadComPic"></i>
          </el-upload>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="时间复杂度" prop="timeComplexity">
              <el-input v-model="form.timeComplexity"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="空间复杂度" prop="spaceComplexity">
              <el-input v-model="form.spaceComplexity"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="submitBtn" style="text-align: center">
        <el-button round type="primary" @click="submitConfirm"
          >确认提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

import { getQuestionDetail, answerQuestion } from "@/service/answerCenter";

import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
import { mapState } from "vuex";
export default {
  name: "AnswerQuestion",
  components: { ElImageViewer, Editor, Toolbar },
  data() {
    const casePicturesValidator = (rule, value, callback) => {
      if (this.form.casePictures.length == 0) {
        callback(new Error("请上传运行截图！"));
      } else {
        callback();
      }
    };
    return {
      questionDetail: {},
      showIndex: 0,
      showViewer: false,
      form: {
        casePictures: [],
        casus: ["", ""],
      },
      editorId: null,

      toolbarConfig: {
        toolbarKeys: [
          "codeBlock",
          "|",
          "bold",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          "emotion",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          "|",
          "redo",
          "undo",
          "|",
          "fullScreen",
        ],
      },
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      rules: {
        idea: [
          {
            required: true,
            message: "请输入思路",
            trigger: "blur",
          },
        ],
        main: [
          {
            required: true,
            message: "请输入主体代码",
            trigger: "change",
          },
        ],
        printScreen: [],
        timeComplexity: [
          {
            required: true,
            message: "请输入时间复杂度",
            trigger: "blur",
          },
        ],
        spaceComplexity: [
          {
            required: true,
            message: "请输入空间复杂度",
            trigger: "blur",
          },
        ],
        "casus[0]": [
          {
            required: true,
            message: "请输入Case1",
            trigger: "blur",
          },
        ],
        casePictures: [
          {
            required: true,
            validator: casePicturesValidator,
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    getQuestionDetail(this.$route.query.id).then((res) => {
      if (res.success) {
        this.questionDetail = res.result;
      }
    });
  },
  computed: {
    ...mapState(["userInfo"]),
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },

  methods: {
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
    handlePicSuccess(res) {
      this.form.casePictures.push(res.result.objectKey);
    },
    beforeUpload(file) {
      if (this.form.casePictures.length >= 5) {
        return this.$message.error("最多只能上传5张图片!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    removeFile(file) {
      let removeIndex = this.form.casePictures.indexOf(
        file.response.result.accessibleUrl
      );
      this.form.casePictures.splice(removeIndex, 1);
    },
    onChange(editor) {
      this.curContent = editor.children;
      this.form.main = editor.getHtml();
    },
    submitConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          answerQuestion({ id: this.$route.query.id, ...this.form }).then(
            (res) => {
              if (res.success) {
                this.$message.success("回答完成，请等待审核！");
                this.$router.go(-1);
              } else {
                this.$message.error(res.message);
              }
            }
          );
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.questionContainer {
  width: 900px;
  margin: 0 auto;
  padding: 30px;
  .mainQuestion {
    margin-top: 30px;
    padding: 14px 60px;
    border: 1px dashed rgba(226, 226, 226, 1);
  }
}
.back {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 21px;
  span {
    cursor: pointer;
  }
}
.form {
  margin-top: 20px;
}
</style>
